<script>
import get from 'lodash-es/get'
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

export default {
  name: 'rec-comment-report-modal',
  components: {
    BaseButton,
    BaseIcon,
    BaseMdl,
  },
  props: {
    comment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      processing: false,
      successful: false,
    }
  },
  methods: {
    async onReport() {
      this.processing = true

      try {
        await this.comment.report()
        this.successful = true
      } catch (error) {
        this.error = get(error, 'response.data.message', 'Network error')
      }

      this.processing = false
    },
    onClose() {
      modal.hide()
    },
  },
}
</script>

<template>
  <base-mdl :layout="false" :closable="!processing" width="xs">
    <div class="relative p-5 sm:p-[30px]">
      <div class="absolute right-0 mr-[30px]">
        <base-button icon="plain/close" @click="onClose" />
      </div>
      <div class="mt-2.5 mb-6 text-center">
        <base-icon
          class="mx-auto"
          :class="{
            'text-fg-success': successful,
            'text-fg-error': !successful,
          }"
          :svg="successful ? 'success' : 'rec/alert'"
          :size="64"
        />
      </div>
      <div class="text-center">
        <template v-if="successful">
          <h2 class="leading-tight">The Comment was reported</h2>
          <base-button class="mt-10" full-width @click="onClose">
            Done
          </base-button>
        </template>
        <template v-else-if="error">
          <h2 class="leading-tight">Error while reporting Comment</h2>
          <p class="mt-[30px]">
            {{ error }}
          </p>
          <p>Please, try again later.</p>
          <base-button class="mt-10" full-width @click="onClose">
            Close
          </base-button>
        </template>
        <template v-else>
          <h2 class="leading-tight">
            Are you sure you wish to Report this Comment?
          </h2>
          <p class="mt-[30px]">
            This request will be sent
            <br />
            to management for review.
          </p>
          <base-button
            class="mt-10"
            full-width
            :loading="processing"
            @click="onReport"
          >
            Report
          </base-button>
        </template>
      </div>
    </div>
  </base-mdl>
</template>
